<template>
    <v-container fluid style="max-width: 100%" class="px-0">
        <h1> DIGITADOR</h1>
        <v-select
          :items="nomesMedicosExecutantes"
          label="Selecione um médico"
          clear-icon="$clear"
          clearable
          filled
          outlined
          @change="handleMedicoChange"
          v-model="medicoSelect"
        >
    </v-select>
      <v-row class="d-flex mt-2 justify-space-between" dense>
        <v-col cols="12" class="pa-0">
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-title
                :id="attrs['aria-labelledby']"
                v-text="item"
              ></v-list-item-title>
            </v-list-item>
          </template>
  
          <template v-slot:item.calories="{ item }">
            <v-chip dark>
              {{ item }}
            </v-chip>
          </template>
        </v-col>
      </v-row>
  
      <v-card class="grey lighten-4 pa-0 ma-0">
        <v-container class="fluid grey lighten-4 pa-0 ma-0">
          <v-row v-if="dataSelecionadaBloqueada">
            <v-col class="text-center">
              <span>
                Data bloqueada, por favor desbloqueie antes para marcar uma
                consulta
              </span>
            </v-col>
          </v-row>
          <v-data-table
            v-else
            class="grey lighten-4 pt-2 px-0 clique maiuscula"
            height="450px"
            hide-default-footer
            :headers="headers1"
            sort-by="hora"
            :items="consultasSelecionadas"
            no-data-text="Nenhuma consulta neste dia "
            style="font-size: 10px !important"
            :items-per-page="-1"
            v-bind="attrs"
            v-on="on"
            @click:row="handleClick"
          >
            <template v-slot:item.hora="{ item }">
              {{ item.hora }}
              <!-- <span >&nbsp;&nbsp; gemelar</span> -->
              <v-chip
                class="ma-2"
                color="green"
                style="font-size: 10px; width: 70px; height: 25px"
                v-if="item.gemelar === 'true'"
              >
                Gemelar
              </v-chip>
            </template>
  
            <template v-slot:item.exames="{ item }">
              <!-- {{ item.exames }}
              {{ typeof item.exames }} -->
              <!-- <abbr
                v-for="(exame, index) in item.exames"
                :title="`${exame}`"
                :key="index"
              >
                <span v-if="item.exames.length == 1">
                  {{ exame }}
                </span>
                <span v-else>
                  {{ getSigla(exame) }}
                  <span v-if="item.exames.length != index + 1">/</span>
                </span>
              </abbr> CODIGO REFERENTE AS SIGLAS DOS EXAMES-->
              <span
                v-for="(exame, index) in item.exames"
                :title="`${exame}`"
                :key="index"
              >
              {{ exame.valorExameConvenio.exame.nome
}} <br />
              </span>
            </template>
  
            <template
              v-slot:item.action="{ item }"
              v-if="valorStatus === 'status'"
            >
              <v-dialog
                :exibir="true"
                transition="dialog-bottom-transition"
                max-width="600"
                scrollable
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    depressed
                    plain
                    v-bind="attrs"
                    v-on="on"
                    router
                    :to="''"
                    @click="setaConsultaSelecionada(item)"
                  >
                    <v-icon>mdi-dots-horizontal-circle-outline</v-icon></v-btn
                  >
                </template>
                <template v-slot:default="dialog">
                  <v-card class="py-4 px-2">
                    <v-card-actions class="d-flex justify-space-between">
                      <v-btn
                        depressed
                        plain
                        small
                        class="text-end pl-0 ml-n1"
                        @click="redirecionaNovoLaudo"
                        route
                        :to="''"
                      >
                        <v-icon>mdi-plus-thick</v-icon>
                        novo laudo
                      </v-btn>
  
                      <v-btn depressed plain small class="text-end">
                        <v-icon @click="dialog.value = false"
                          >mdi-close-circle-outline</v-icon
                        >
                      </v-btn>
                    </v-card-actions>
                    <template class="text-center">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">EXAME</th>
                              <th class="text-left">MÉDICO</th>
                              <th class="text-left">DATA</th>
                              <th class="text-left">AÇÕES</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                item, indexHistorico
                              ) in historicoFinalizado"
                              :key="item.name"
                              class="text-left pointer"
                            >
                              <!--@click="irParaOsLaudos(item) -->
                              <td>
                                <v-checkbox
                                  v-for="(exame, index) in item.exames"
                                  v-model="
                                    laudoCheckbox[indexHistorico][exame.nome]
                                  "
                                  class="mt-0 mb-0 pt-0 pb-0"
                                  hide-details
                                  :label="exame.nome"
                                  validate-on-blur
                                  :key="index"
                                ></v-checkbox>
                                <!-- <p
                                  v-for="(exame, index) in item.exames"
                                  :key="index"
                                  class="py-0 my-0"
                                >
                                  &bull; {{ exame.exame }}
                                </p> -->
                              </td>
                              <td>{{ item.nomeMedico }}</td>
                              <td>{{ item.data }}</td>
                              <td>
                                <v-btn
                                  icon
                                  @click="
                                    () => imprimirLaudo(item, indexHistorico)
                                  "
                                >
                                  <v-icon>mdi-printer</v-icon>
                                </v-btn>
                                <v-btn
                                  icon
                                  @click="
                                    () => irParaOsLaudos(item, indexHistorico)
                                  "
                                >
                                  <v-icon>mdi-text-box-edit</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <p
                        class="text--disabled text-center maiuscula"
                        v-if="
                          historicoFinalizado.length === 0 && loading === false
                        "
                      >
                        {{ consultaSelecionada.paciente.nome }} ainda não possui
                        laudos.
                      </p>
  
                      <v-progress-linear
                        v-show="loading"
                        indeterminate
                      ></v-progress-linear>
                    </template>
                  </v-card>
                </template>
              </v-dialog>
            </template>
            <template
              v-slot:item.status="{ item }"
              v-if="valorStatus === 'status'"
            >
              <v-chip
                v-if="item.status || item.statusPagamento"
                :color="getColor(item.status, item.statusPagamento)"
                small
                style="width: 75px"
                width="30px"
                class="tamanho"
              >
                <span style="font-size: 10px">{{ item.status }}</span>
              </v-chip>
            </template>
  
            <template
              v-slot:item.statusPagamento="{ item }"
              v-if="valorStatus === 'statusPagamento'"
            >
              <v-chip
                v-if="item.status || item.statusPagamento"
                :color="getColor(item.status, item.statusPagamento)"
                small
                width="30px"
                class="tamanho"
              >
                <span style="font-size: 8px">{{ item.statusPagamento }}</span>
              </v-chip>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
      <LoadingDialog :loading="loadingDialog" />
    </v-container>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import LoadingDialog from '../LoadingDialog.vue';
  import ConsultasMixin from '../../mixins/ConsultasMixin';
  import MedicoMixin from '../../mixins/MedicoMixin';
  import CalendarioMixin from '../../mixins/CalendarioMixin';
  import PacienteMixin from '../../mixins/PacienteMixin';
  import LoginMixin from '../../mixins/LoginMixin';
  import LaudosMixin from '../../mixins/LaudosMixin';
  import MetricasMixin from '../../mixins/MetricasMixin';
  import ListIcon from '../../assets/black-24dp/1x/baseline_list_black_24dp.png';
  import axios from 'axios';
  import moment from 'moment';
  
  export default {
    name: 'ListaDeConsultasMedicos',
    mixins: [
      ConsultasMixin,
      MedicoMixin,
      CalendarioMixin,
      PacienteMixin,
      LoginMixin,
      LaudosMixin,
      MetricasMixin,
    ],
    components: { ListIcon, LoadingDialog },
    data() {
      return {
        pacienteAgendamento: false,
        expanded: [],
        medicoSelect: null,
        loadingDialog: false,
        historico: [],
        historicoFinalizado: [],
        singleExpand: false,
        pacienteParaAgendamento: {},
        medicoSelect: null,
        valorStatus: '',
        status: [
          'Desistente',
          'Ausente',
          'Em espera',
          'Em atendimento',
          'Atendido',
        ], // o status Atendido tem que sair daqui
        avisos: [
          'Olá! Faltam 30 minutos para o seu exame.',
          'Olá! Você já pode se encaminhar para a clínica.',
          'Olá! Você está atrasado para o seu exame.',
          'Confirmar comparecimento.',
        ],
        laudoCheckbox: [],
        dialog: false,
        loading: false,
        listaExamesPopUp: false,
        on: '',
        attrs: '',
        exibe: false,
        examesParaImpressao: null,
        selected: {},
        componentKey: 0,
      };
    },
    computed: {
      ...mapGetters('Auth', ['headers']),
      ...mapGetters('Calendario', ['dataSelecionadaBloqueada']),
      headers1() {
        return [
          { text: 'Horário', value: 'hora', width: '15%' },
          { text: 'Médico', value: 'medico.nome', align: ' d-none' },
          {
            text: 'Exame',
            value: 'exames',
            width: '25%',
          },
          { text: 'Convênio', value: 'convenio', width: '18%' },
          { text: 'Paciente', value: 'paciente.nome', width: '45%' },
          {
            text: 'Status',
            value: this.valorStatus,
            width: '5%',
            align: ' center',
          },
  
          { text: ' ', value: 'action', sortable: false },
        ];
      },
  
      dataSelecionadaBloqueada() {
        return this.$store.getters['Calendario/dataSelecionadaBloqueada'];
      },
  
      usuarioLogado() {
        return this.$store.getters['Auth/usuarioLogado'];
      },
    },
    methods: {
      redirecionaNovoLaudo() {
        this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
      },
  
      criaLista(items) {
        return items.map(item => item.exame);
      },
  
      getSigla(value) {
        if (value === 'TRANSLUCÊNCIA NUCAL') {
          return 'TN';
        }
        if (value === 'DOPPLER') {
          return 'DP';
        }
        if (value === 'PERFIL CERVICAL') {
          return 'PC';
        }
        if (value === 'COLO UTERINO') {
          return 'CUT';
        }
        if (value === 'AVALIAÇÃO MORFOLÓGICA DO PRIMEIRO TRIMESTRE') {
          return 'M1T';
        }
  
        return value;
      },
      async handleMedicoChange(nome) {
     
        this.loadingDialog = true;
        
        if (!nome) {
          this.resetaMedicoSelecionado();
          await this.getConsultaByData(this.dataSelecionada).then(() => {
            this.loadingDialog = false;
          });
  
          return;
        }
        let medico = await this.getMedicoByNome(nome);
        this.setMedicoSelecionado(medico).then(()=>{
           
        });
       
  
        let idMed = this.medicoSelecionado.id;
        if (!!medico) {
          idMed = medico.id;
        }
        idMed = this.medicoSelecionado.id;
        await this.getConsultaByDataMedico({
          medicoId: idMed,
          data: this.dataSelecionada,
        }).then(() => {
          this.loadingDialog = false;
        });
        this.getMetricas({
        data: this.dataSelecionada,
        medicoId: this.medicoSelecionado.id,})
      },
      async setaConsultaSelecionada(item) {
        
        item.data = moment(item.data).format('DD/MM/YYYY')

    item.id = item.consultaId || item.id;
        await this.setConsultaSelecionada(item);
  
        await this.getLaudosHistorico()
          .then(() => {})
          .catch(e => (this.loading = false));
      },
      async getLaudosHistorico() {
        this.loading = true;
        this.historicoFinalizado = [];
        // await -> esperar
        const { data } = await this.getLaudoPorPaciente(
          this.consultaSelecionada.paciente.id
        );
        this.historico = data.map(exames => {
          return {
            consultaId: exames.consultaComExames.consultaId,
            examesId: exames.consultaComExames.exames?.map(
              e => e.idExamePorConsulta
            ),
            exames: exames.consultaComExames.exames,
            nomeMedico: exames.consultaComExames.medicoExecutante.nome,
            data: moment(exames.consultaComExames.data).format('DD/MM/YYYY'),
            exame: exames.consultaComExames.exames?.map(e => e.nome).join(', '),
            medicoExecutante: exames.consultaComExames.medicoExecutante.nome,
            medicoSolicitante: exames.consultaComExames.medicoSolicitante.nome,
          };
        }); //teste
  
        this.historico.sort((a, b) => {
          const dataFormatadaA = a.data.split('/');
          const dataFormatadaB = b.data.split('/');
          const dataA = new Date(
            dataFormatadaA[2],
            dataFormatadaA[1],
            dataFormatadaA[0]
          );
          const dataB = new Date(
            dataFormatadaB[2],
            dataFormatadaB[1],
            dataFormatadaB[0]
          );
          return dataB - dataA; //caso queira inverter as datas colocar dataA - dataB;
        });
  
        // await this.ordenaConsultasMedico();
        this.loading = false;
        this.historicoFinalizado = this.historico;
        this.laudoCheckbox = [];
  
        this.historico.forEach(h => {
          const obj = {};
          h.exame.split(', ').forEach(e => (obj[e] = false));
          this.laudoCheckbox.push(obj);
        });
  
        const historicoFormatado = [];
        for (const index in this.historico) {
          // O meu prox não pode ser apenas o prox index, mas sim todos os que estão na mesma data, então .find?;
          // const teste = this.historico.find((h)=>h.data === atual.data);
          const atual = this.historico[index];
          const prox = this.historico[index + 1];
          if (!prox) return;
          if (atual.data === prox.data && atual.nomeMedico === prox.nomeMedico) {
            const exameFormatado = `${atual.exame}, ${prox.exame}`;
            historicoFormatado.push({ ...atual, exame: exameFormatado });
          }
        }
      },
      async ordenaConsultasMedico() {
        const consultasFiltradas = [];
        const encontrou = [];
        for (var i = 0; i < this.historico.length; i++) {
          for (var j = 0; j < this.historico.length; j++) {
            if (
              this.historico[i].consultaId == this.historico[j].consultaId &&
              !encontrou.includes(this.historico[i].consultaId)
            ) {
              encontrou.push(this.historico[i].consultaId);
  
              consultasFiltradas.push(this.historico[i]);
            }
          }
        }
  
        for (var k = 0; k < consultasFiltradas.length; k++) {
          for (
            var i = 0;
            i < Object.keys(Object.assign({}, resp.data)).length;
            i++
          ) {
            let exame = {
              exame: teste[i].valorExameConvenio.exame.nome,
            };
            examesPorConsulta.push(exame);
          }
          consultasFiltradas[k].exames = examesPorConsulta;
        }
        this.historico = consultasFiltradas;
      },
  
      handleBotaoCadastro() {
        const size = this.$refs.pacientesAgendamento.filteredItems.length;
        this.pacienteAgendamento = size == 0;
      },
      handlePacienteChange(pacienteNome) {
        this.setPacienteByNome(pacienteNome).then(() => {
          if (this.pacienteById.id != undefined) {
            this.getPacienteById(this.pacienteById.id);
            this.redirecionaParaAgendamento();
          }
        });
      },
      mudaStatus(status) {
        this.consultaSelecionada.status = status;
      },
      setStatus() {
        this.alterarStatus().then(() =>
          this.getMetricas({
            data: this.dataSelecionada,
            medicoId: this.usuarioLogado.id,
          })
        );
      },
      currentRouteName() {
        if (this.$route.name === 'HomeMedico') {
          this.valorStatus = 'status';
        } else {
          this.valorStatus = 'statusPagamento';
        }
      },
      getColor(status, statusPagamento) {
        if (this.valorStatus === 'status') {
          return this.getColorStatus(status);
        } else if (this.valorStatus === 'statusPagamento') {
          return this.getColorStatusPagamento(statusPagamento);
        }
      },
      getColorStatusPagamento: function (statusPagamento) {
        if (statusPagamento === 'Pago') {
          return 'green accent-2';
        } else if (statusPagamento === 'Em análise') {
          return 'orange accent-2';
        } else if (statusPagamento === 'Pendente') {
          return 'red accent-2';
        }
      },
      getColorStatus: function (status) {
        if (status === 'Atendido' || status === 'ATENDIDO') {
          return 'green accent-2';
        } else if (status === 'Em espera' || status === 'EM ESPERA') {
          return 'deep-orange accent-2';
        } else if (status === 'Em atendimento' || status === 'EM ATENDIMENTO') {
          return 'amber accent-2';
        } else if (status === 'Ausente' || status === 'AUSENTE') {
          return 'red accent-2';
        } else if (status === 'Desistente' || status === 'DESISTENTE') {
          return 'blue-grey lighten-2';
        } else if (status === 'Laudado' || status === 'LAUDADO') {
          return 'blue darken-2';
        }
      },
      handleClick(event) {
        if (
          this.$route.name == 'HomeMedico' &&
          this.nivel == 1 &&
          event.id != undefined
        ) {
          this.listaExamesPopUp = true;
        }
      },
      exibirBotaoNovoCadastro(paciente) {
        if (this.paciente == undefined) this.pacienteAgendamento = true;
      },
      setSelected(event) {
        this.setPacienteByNome(event);
      },
      redirecionaParaAgendamento() {
        this.$router.push(
          `/agendamento/?hora=${this.pacienteParaAgendamento.hora}&medico=${this.medicoSelecionado.id}&data=${this.dataSelecionada}&paciente=${this.pacienteById.id}`
        );
      },
      redirecionaNovoCadastro() {
        this.$router.push(
          `/cadastrar-paciente/?hora=${this.pacienteParaAgendamento.hora}&medico=${this.medicoSelecionado.id}&data=${this.dataSelecionada}`
        );
      },
  
      // Laudos que não devem abrir sozinhos, AXILA, OBSTETRICADOPPLER,
      async imprimirLaudo(item, index) {
        const historico = this.laudoCheckbox[index];
  
        let ids = [];
        let nomes = [];
        for (const chave in historico) {
          if (historico[chave]) {
            nomes.push(chave.toLowerCase());
            ids.push(item.examesId[item.exames.findIndex(e => e.nome === chave)]);
          }
        }
        if (nomes.length === 0) {
          alert('SELECIONE PELO MENOS UMA OPÇÃO VÁLIDA');
          return;
        }
        let id;
        let nome;
        const indexComposto = nomes.findIndex(
          exame =>
            exame === 'translucência nucal' ||
            exame === 'axila' ||
            exame === 'morfologica' ||
            exame === 'obstetrica' ||
            exame === 'transvaginal'
        );
        let rota;
        if (nomes.includes('axila') && !nomes.includes('mama')) {
          alert('A VARIAÇÃO AXILA SÓ EXISTE ACOMPANHADA DE MAMA');
          return;
        }
        if (
          nomes.length === 2 &&
          nomes.includes('perfil cervical') &&
          nomes.includes('doppler')
        ) {
          id = ids[0];
          rota = { query: { exameId: id, via: '2' } };
          rota.name = `imprecoes.perfilCervicalComDoppler`;
        } else if (nomes.length > 1) {
          id = ids[indexComposto];
          rota = { query: { exameId: id, via: '2' } };
  
          if (nomes.includes('axila') && nomes.includes('mama')) {
            rota.name = `imprecoes.axila`;
            // } else if (nomes.includes('axila') && !nomes.includes('mama')) {
            //   alert('A VARIAÇÃO AXILA SÓ EXISTE ACOMPANHADA DE MAMA');
            //   return;
          } else if (
            nomes.includes('morfologica') &&
            nomes.includes('perfil cervical') &&
            nomes.length === 2
          ) {
            rota.name = `imprecoes.morfologicaComCervical`;
          } else if (
            nomes.includes('transvaginal') &&
            nomes.includes('doppler') &&
            nomes.length === 2
          ) {
            rota.name = `imprecoes.transvaginalUteroComDoppler`;
          } else if (
            nomes.includes('obstetrica') &&
            nomes.includes('doppler') &&
            nomes.length === 2
          ) {
            rota.name = `imprecoes.obstetricaDoppler`;
          } else if (
            nomes.includes('morfologica') &&
            nomes.includes('doppler') &&
            nomes.length === 2
          ) {
            rota.name = `imprecoes.morfologicaComDoppler`;
          } else if (
            nomes.includes('obstetrica') &&
            nomes.includes('perfil cervical') &&
            nomes.length === 2
          ) {
            rota.name = `imprecoes.obstetricaPerfilCervical`;
          } else if (
            nomes.includes('obstetrica') &&
            nomes.includes('perfil cervical') &&
            nomes.includes('doppler') &&
            nomes.length === 3
          ) {
            rota.name = `imprecoes.obstetricaDopplerPerfilCervical`;
          } else if (
            nomes.includes('morfologica') &&
            nomes.includes('perfil cervical') &&
            nomes.includes('doppler') &&
            nomes.length === 3
          ) {
            rota.name = `imprecoes.morfologicaComDopplerCervical`;
          } else if (
            nomes.includes('perfil cervical') &&
            nomes.includes('doppler') &&
            nomes.length === 2
          ) {
            rota.name = `imprecoes.perfilCervicalComDoppler`;
          } else if (
            nomes.includes('obstetrica') &&
            nomes.includes('doppler') &&
            nomes.includes('ecocardiograma') &&
            nomes.length === 3
          ) {
            rota.name = `imprecoes.obstetricaComDopplerEEcocardiodoppler`;
          } else if (nomes.includes('translucência nucal')) {
            rota.name = `imprecoes.translucencianucal`;
          } else {
            alert('ESSA COMBINAÇÃO NÃO É SUPORTADA');
            return;
          }
        } else {
          id = ids[0];
          nome = nomes[0].replace(/\s+/g, '');
          rota = { query: { exameId: id, exame: id, via: '2' } };
          rota.name = `imprecoes.${nome}`;
          if (nomes[0] === 'avaliação morfológica do primeiro trimestre') {
            rota.name = `imprecoes.avaliacaomorfologicadoprimeirotrimestre`;
          }
          if (nomes[0] === 'ecocardiograma') {
            rota.name = `imprecoes.ecocardiodopplerfetal`;
          }
          if (
            nomes[0] === 'pélvica transvaginal com doppler e preparo intestinal'
          ) {
            rota.name = `imprecoes.pelvicatransvaginaldopplerpreparointestinal`;
          }
          if (nomes[0] === 'transvaginal') {
            rota.name = `imprecoes.pelvicaTransvaginalUtero`;
          }
          if (nomes[0] === 'vilo corial') {
            rota.name = `imprecoes.viloCorial`;
          }
          if (nomes[0] === 'pessário') {
            rota.name = `imprecoes.colocacaoDoPessario`;
          }
          if (nomes[0] === 'amniocentese') {
            rota.name = `imprecoes.amniocentese`;
          }
          if (nomes[0] === 'perfil biofísico fetal ') {
            rota.name = `imprecoes.perfilBiofisicoFetal`;
          }
          if (nomes[0] === 'pélvica') {
            rota.name = `imprecoes.pelvica`;
          }
          if (nomes[0] === 'seriada') {
            rota.name = `imprecoes.seriada`;
          }
          if (nomes[0] === 'neurosonografia') {
            rota.name = `imprecoes.neurosonografia`;
          }
          if (nomes[0] === 'transvaginal tridimensional') {
            rota.name = `imprecoes.transvaginalUteroComTridimensional`;
          }
          if (nomes[0] === 'avaliação de reserva ovariana') {
            rota.name = `imprecoes.transvaginalUteroParaAvaliacaoDeReservaOvariana`;
          }
          if (nomes[0] === 'endometriose') {
            rota.name = `imprecoes.transvaginalUteroParaPesquisaDeEndometriose`;
          }
          if (nomes[0] === 'obstetrica 1ª trimestre') {
            rota.name = `imprecoes.obstetrica1trimestre`;
          }
          if (nomes[0] === 'pélvica transabdominal') {
            rota.name = `imprecoes.pelvicatransabdominal`;
          }
          if (nomes[0] === 'translucência nucal') {
            rota.name = `imprecoes.translucencianucal`;
          }
        }
  
        for (const key in historico) {
          historico[key] = false;
        }
  
        this.$forceUpdate;
  
        if (!(await this.laudoExists(id))) {
          alert(
            'O exame selecionado foi laudado com variações.\nSelecione mais exames para visualizar o laudo corretamente'
          );
          return;
        }
        await this.getLaudoByExamePorConsultaId(id);
  
        const novaAba = this.$router.resolve(rota);
        window.open(novaAba.href, '_blank');
      },
  
      irParaOsLaudos(laudo, index) {
        const historico = this.laudoCheckbox[index];
        const ids = [];
        const nomes = [];
        for (const chave in historico) {
          if (historico[chave]) {
            nomes.push(chave.toLowerCase());
            ids.push(
              laudo.examesId[laudo.exames.findIndex(e => e.nome === chave)]
            );
          }
        }
        if (nomes.length === 0) {
          alert('SELECIONE PELO MENOS UMA OPÇÃO VÁLIDA');
          return;
        }
        // laudo.exames.map(exame => exame.exame);
  
        let variacoes = nomes;
        let passouPorTn = false;
        let variacoesTn = [];
        let exameMinusculo;
  
        if (nomes.includes('axila') && !nomes.includes('mama')) {
          alert('A VARIAÇÃO AXILA SÓ EXISTE ACOMPANHADA DE MAMA');
          return;
        }
        if (
          variacoes.includes('perfil cervical') &&
          variacoes.includes('doppler') &&
          variacoes.length === 2
        ) {
          exameMinusculo = 'perfil cervical com doppler';
          const indexPc = variacoes.findIndex(
            exame => exame === 'perfil cervical'
          );
          laudo.exameId = laudo.examesId[indexPc];
        } else if (variacoes.length > 1) {
          const indexComposto = variacoes.findIndex(
            exame =>
              exame === 'translucência nucal' ||
              exame === 'axila' ||
              exame === 'morfologica' ||
              exame === 'obstetrica' ||
              exame === 'transvaginal'
          );
          exameMinusculo = variacoes[indexComposto];
          laudo.exameId = ids[indexComposto];
  
          if (
            variacoes.includes('morfologica') &&
            variacoes.includes('doppler') &&
            variacoes.includes('perfil cervical')
          ) {
            exameMinusculo = 'morfologica com cervical e doppler';
          } else if (
            variacoes.includes('morfologica') &&
            variacoes.includes('doppler')
          ) {
            exameMinusculo = 'morfologica com doppler';
          } else if (
            variacoes.includes('obstetrica') &&
            variacoes.includes('ecocardiograma') &&
            variacoes.includes('doppler')
          ) {
            exameMinusculo = 'obstetrica com doppler e ecocardiodoppler';
          } else if (
            variacoes.includes('obstetrica') &&
            variacoes.includes('perfil cervical') &&
            variacoes.includes('doppler')
          ) {
            exameMinusculo = 'obstetricaDopplerPerfilCervical';
          } else if (
            variacoes.includes('morfologica') &&
            variacoes.includes('perfil cervical')
          ) {
            exameMinusculo = 'morfologica com cervical';
          } else if (
            variacoes.includes('obstetrica') &&
            variacoes.includes('doppler')
          ) {
            exameMinusculo = 'obstetricaDoppler';
          } else if (
            variacoes.includes('obstetrica') &&
            variacoes.includes('perfil cervical')
          ) {
            exameMinusculo = 'obstetricaPerfilCervical';
          } else if (
            variacoes.includes('transvaginal') &&
            variacoes.includes('doppler')
          ) {
            exameMinusculo = 'transvaginal com doppler';
          } else if (
            variacoes.includes('perfil cervical') &&
            variacoes.includes('doppler') &&
            variacoes.includes('translucência nucal') &&
            variacoes.includes('avaliação morfológica do primeiro trimestre')
          ) {
            variacoesTn = [
              'doppler',
              'perfil cervical',
              'avaliação morfológica do primeiro trimestre',
            ];
            exameMinusculo = 'translucência nucal';
            passouPorTn = true;
          } else if (
            variacoes.includes('perfil cervical') &&
            variacoes.includes('doppler') &&
            variacoes.includes('translucência nucal')
          ) {
            variacoesTn = ['doppler', 'perfil cervical'];
            exameMinusculo = 'translucência nucal';
            passouPorTn = true;
          } else if (
            variacoes.includes('translucência nucal') &&
            variacoes.includes('avaliação morfológica do primeiro trimestre') &&
            variacoes.includes('doppler')
          ) {
            variacoesTn = [
              'doppler',
              'avaliação morfológica do primeiro trimestre',
            ];
            exameMinusculo = 'translucência nucal';
            passouPorTn = true;
          } else if (
            variacoes.includes('translucência nucal') &&
            variacoes.includes('avaliação morfológica do primeiro trimestre') &&
            variacoes.includes('perfil cervical')
          ) {
            variacoesTn = [
              'perfil cervical',
              'avaliação morfológica do primeiro trimestre',
            ];
            exameMinusculo = 'translucência nucal';
            passouPorTn = true;
          } else if (
            variacoes.includes('translucência nucal') &&
            variacoes.includes('doppler')
          ) {
            variacoesTn = ['doppler'];
            exameMinusculo = 'translucência nucal';
            passouPorTn = true;
          } else if (
            variacoes.includes('translucência nucal') &&
            variacoes.includes('perfil cervical')
          ) {
            variacoesTn = ['perfil cervical'];
            exameMinusculo = 'translucência nucal';
            passouPorTn = true;
          } else if (
            variacoes.includes('translucência nucal') &&
            variacoes.includes('avaliação morfológica do primeiro trimestre')
          ) {
            variacoesTn = ['avaliação morfológica do primeiro trimestre'];
            exameMinusculo = 'translucência nucal';
            passouPorTn = true;
          } else if (variacoes.includes('mama') && variacoes.includes('axila')) {
            exameMinusculo = 'axila';
          } else {
            alert('ESSA COMBINAÇÃO NÃO É SUPORTADA');
            return;
          }
        } else {
          exameMinusculo = variacoes[0];
          exameMinusculo = exameMinusculo;
          laudo.exameId = ids[0];
        }
  
        this.consultaSelecionada.liberada = true;
        this.consultaSelecionada.id = laudo.consultaId;
        this.consultaSelecionada.data = laudo.data;
        this.consultaSelecionada.medicoSolicitante = laudo.medicoSolicitante;
        this.consultaSelecionada.medicoExecutante = laudo.medicoExecutante;
  
        let query;
  
        if (passouPorTn) {
          query = {
            variacoes: variacoesTn,
            consulta: laudo.consultaId,
            exame: laudo.exameId,
            editar: true,
          };
        } else {
          query = {
            consulta: laudo.consultaId,
            exame: laudo.exameId,
            editar: true,
          };
        }
        this.$router.push({ name: `laudo.${exameMinusculo}`, query });
      },
    },
    beforeMount() {
      this.currentRouteName();
    },
    watch: {
      '$store.state.calendario.dataSelecionada': function () {
        this.loadingDialog = true;
  
        if (this.medicoSelecionado.id != undefined) {
          this.handleMedicoChange(this.medicoSelecionado.nome);
        }
        this.getMetricas({
          data: this.dataSelecionada,
          medicoId: this.usuarioLogado.medico.id,
        });
        this.getdatasBloqueadas(this, this.medicoSelecionado.id);
        this.loadingDialog = false;
      },
  
      dataSelecionada: {
        async handler(value) {
          if (!value) return;
  
          this.handleMedicoChange(this.medicoSelecionado.nome);
        },
        immediate: true,
      },
    },
    mounted() {
      this.getPacientes();
      this.getNomesPacientes(this.token);
  
      this.getMetricas({
        data: this.dataSelecionada,
        medicoId: this.medicoSelecionado.id,
      });
  
      this.handleMedicoChange(this.usuarioLogado.medico.nome).then(() => {
        this.medicoSelect = this.usuarioLogado.medico.nome;
      });
    },
    created() {
      this.getNomesMedicosExecutanteSolicitante().then(() => {
        //this.medicoSelect = this.nomesMedicosExecutantes[0];
        if (!!this.medicoSelecionado.nome) {
          this.medicoSelect = this.medicoSelecionado.nome;
          this.handleMedicoChange(this.medicoSelecionado.nome);
        } else {
          this.medicoSelect = this.nomesMedicosExecutantes[0];
          this.handleMedicoChange(this.nomesMedicosExecutantes[0]);
        }
      });
      try {
        this.getConsultaByDataMedico({
          medicoId: this.medicoSelecionado.id ? this.medicoSelecionado.id : 1,
          data,
        }).then(() => this.adicionaEspacos());
      } catch {
        this.adicionaEspacos();
      }
    },
    beforeDestroy() {
      // this.resetaMedicoSelecionado();
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .pointer {
    cursor: pointer;
  }
  
  .v-data-table::v-deep td {
    font-size: 12px !important;
  }
  </style>
  